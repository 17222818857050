.canvasjs-chart-credit {
    display: none;
}

.chartContainer {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullscreen .chartContainer > div {
    height: auto !important;
}

.canvasjs-chart-tooltip {
    z-index: 98 !important;
}