

#moveContent {
  background: url(../../assets/Images/body_bg.jpg) no-repeat center bottom / cover;

  &.vehicleWrapper {
    .sliding-panel-container {
      width: 550px;
      background: #fff8ea;
      top: 0;

      .group_detailsWrapper {
        overflow: auto;
        padding: 25px;
        height: calc(100vh - 50px);
        padding-bottom: 8px;

        .bg-light {
          background-color: #ffe7c8 !important
        }
      }
    }

    .paneIsOpen {
      width: calc(100% - 550px);
    }

    // .blank_height {
    //     min-height: calc(100vh - 243px);
    //   }
    .card_height {
      height: calc(100vh - 246px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .Fuel-table {
      height: calc(100vh - 246px);
    }
  }
}

.vehicle-100 .vehicle_card {
  height: 100%;
}

.vehicle-two-card .vehicle_card {
  max-height: 23.6rem;

}

.vehicle-two-card {
  margin-top: 0px;
}

.vehicle-100 {
  margin-top: 0px;
  margin-bottom: 13px;

  .bg-light {
    background-color: #ffe7c8 !important
  }

  .timeline::before {
    background: linear-gradient(0deg, #ffe7c8 0%, #999999 50%, #ffe7c8 100%);
  }

  .progress {
    background: #e3d2b2;
  }

  .tableFooter.py-1.row {
    position: fixed;
    bottom: 0;
    width: 100%;
    visibility: hidden;
  }

  .tableFooter.py-1.row:hover {
    width: 100%;
    visibility: visible;
  }
}

.timeline {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 3px;
    height: 100%;
    top: 0;
    background: linear-gradient(0deg, #fff 0%, #999999 50%, #fff 100%);
    border-radius: 4px;
  }

  &.horizontal {
    display: flex;

    &::before {
      width: 100%;
      height: 3px;
      background: linear-gradient(90deg, #fff8ea 0%, #999999 50%, #fff8ea 100%);
    }

    .timeline-item {
      padding: 0;
      width: 50%;
      margin-top: -20px;

      &:last-child {
        text-align: end;

        &::before {
          left: calc(100% - 54px);
        }

        h4 {
          padding-left: 0;
          padding-right: 40px;
        }
      }

      &::before {
        top: 16px;
        left: 50px
      }

      small {
        padding-bottom: 10px;
        display: block;
      }

      h4 {
        padding-left: 50px;
      }
    }
  }

  .timeline-item {
    position: relative;
    padding: 10px 15px;

    &::before {
      content: '';
      position: absolute;
      left: -3px;
      width: 10px;
      height: 10px;
      top: 20px;
      background: #999;
      border-radius: 4px;
    }

    .h6,
    h6 {
      font-size: 0.9rem;
    }
  }
}

.progress_parent {
  background: url(../../assets/Images/cargo.png) no-repeat top left / auto 100%;
  padding: 4px 4px 27px 68px;
  width: 190px;
  height: 91px;
  margin: 20px auto 0;
  width: 190px;

  &.detail_view {
    padding: 6px 6px 59px 138px;
    width: 382px;
    height: 185px;

    .progress {
      border-radius: 20px 5px 5px 5px;
    }
  }

  .progress {
    height: 100%;
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
    position: relative;

    .progress-bar {
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);

      &.bg-success {
        background-color: #0fb94a !important;
      }
    }

    .volume_fig {
      font-size: 1.4rem;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      line-height: 1;
      padding-top: 50px;

      strong {
        font-size: 18;
      }
    }

  }

}

.paneIsOpen .col-12.col-xxl-6.col-xl-6.col-lg-12 {
  flex: 0 0 auto;
  width: 100%;
}

.tableAccordian-switch .form-check-input {
  opacity: 0;
}

.tableAccordian-switch label {
  border: 1px solid #d3bb9d;
  margin: 0 10px;
  display: block;
  border-radius: 4px;
  overflow: hidden;
}

.tableAccordian-switch label svg {
  padding: 5px;
  cursor: pointer;
  background-color: #ffe7c8;
  border: 1px solid #ffe7c8;
  width: auto;
  height: 30px;
  fill: #999999;
  transition: all 0.3s linear;
}

.tableAccordian-switch input:not(:checked)+label svg:nth-child(2),
.tableAccordian-switch input:checked+label svg:first-child {
  background-color: #AA000D;
  cursor: default;
  border: 1px solid #AA000D;
  color: #fff;
}

.tableAccordian-switch input+label svg:nth-child(2) {
  border-radius: 0 5px 5px 0;
}

.tableAccordian-switch input+label svg:first-child {
  border-radius: 5px 0 0 5px;
}

.NOData {
  width: 200px;
}

.filter-vehicle .user-form .errorMessage {
  font-size: 11px;
  position: inherit;
  bottom: -14px;
}

.timeline-item h5 {
  font-size: 15px;
}

.allocation-period {
  display: inline-block;
}

.vehicle_card {
  background: #fff8ea;

  .bg-light {
    background-color: #ffe7c8 !important;
  }
}

@media (max-width: 1199.98px) {
  .vehicle-two-card .vehicle_card {
    height: auto;
  }
}

@media (max-width: 991.98px) {
  #moveContent.vehicleWrapper .paneIsOpen {
    width: 100%;
  }

  #moveContent.vehicleWrapper .sliding-panel-container {
    width: 100%;
  }
  #moveContent.vehicleWrapper .card_height {
    height: auto;
  }

  //   .progress_parent {
  //     width: 100%;
  //   }
}

@media (max-width: 767.98px) {
  .vehicle_card.h-100 {
    height: inherit !important;
  }
}