#moveContent {
  background: url(../../assets/Images/body_bg.jpg) no-repeat center bottom / cover;

  &.reportWrapper {
    min-height: calc(100vh - 199px);
    .sliding-panel-container {
      width: 600px;
      background: #fff8ea;
      top: 0;


      .group_detailsWrapper {
        overflow: auto;
        padding: 0px;
        .bg-light {
          background-color: #ffe7c8 !important
        }
        table.table {
          border: 1px solid #eee;
      }
      }
    }

    .paneIsOpen {
      width: calc(100% - 600px);
    }

    .blank_height {
      min-height: calc(100vh - 250px);
    }

    .truck-wise-transaction {
      min-height: 105px;
     
    }

    .rounded {
      border-radius: 20px !important;
      border: 1px solid #f2f2f2;
      position: relative;
      .badge {
        position: absolute;
        right: 14px;
        top: 18px;
        cursor: pointer;
      }
    }

    .bg-vehicles-purple {
      background: #f9d8e0;
    }

    .bg-department-blue {
      background: #bae2e0ad;

    }

    .bg-customer-orange {
      background: #F2C99E;

    }

    .bg-tanks-green {
      background: rgba(159, 193, 127, 0.4) !important;

    }
    .bg-payment-yellow {
      background: #fbc64473;

    }
  }

}

.paneIsOpen .row-cols-md-4>* {
  flex: 0 0 auto;
  width: 50%;
}

.cross-icon {
  padding-right: 14px;

  svg {
    line-height: 10px;
    margin-top: -5px;
  }

}

.download-report {
  border: 1px solid #eee;
}
.mt-1.table.table-striped.table-bordered.table-hover td:last-child {
  width: 1px;
  white-space: nowrap;
}

.mt-1.table.table-striped.table-bordered.table-hover td:first-child {
  width: 1px;
  white-space: nowrap;
}

.addbutton.refresh-report {
  padding: 2px 10px !important;
  margin-top: -4px;
}
.NoReport {
  width: 300px;
}
.no_data {
  display: table-cell;
  vertical-align: middle;
  font-family: Oswald;
}
.no-report-data {
  width: 100%;
  display: table;
  height: 100%;
}
@media  (max-width: 768px) { 
  #moveContent.reportWrapper .sliding-panel-container {
    width: 100%;
  }
  #moveContent.reportWrapper .paneIsOpen {
    width: 100%;
  }
  .paneIsOpen .row-cols-md-4 > * {
    width: 100%;
  }
 }
