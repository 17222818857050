$maroon: #AA000D;
$white: #fff;
$black: #000;

.header {
  background: $maroon;
 .nav-item {
 a {
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 15px;
  margin: 0 1px;

}
.active {
  background: $white;
  color: $black !important;
}
:hover {
  color: $black !important;
}

}
.submenu.dropdown {
  .dropdown-toggle::after {
    display: inline-block !important;
  }
  .btn-primary.dropdown-toggle {
    background-color: transparent;
    border: 0px !important;
  padding: 0 10px !important;
    border-radius: 6px;
    font-size: 15px;
    margin: 0 1px;
  }
  .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
  .dropdown-item {
    padding: 10px 15px;
    border-radius: 0px;
  }
  .dropdown-menu {
    padding: 0px;
    overflow: hidden;
  }
}
}
  
@media (max-width: 1300px) {
  .header .nav-item a {
   margin: 0 !important;
   padding: 4px 11px !important;
   font-size: 14px;
  }
 
  }
  @media (max-width: 1200px) {
  .header .nav-item a {
    padding: 4px 9px !important;
    
  }
}
  @media (max-width: 1100px) {
    .header .nav-item a {
     padding: 4px 6px !important;
    }
    
    }
@media (max-width: 991.98px) {
  .collapsed .close-menu {
  display: none;
}
 .close-menu {
  display: block;
}
.collapsed .open-menu {
  display: block;
}
 .open-menu {
  display: none;
}
  .menu-Responsive .material-symbols-outlined {
    font-size: 35px;
  }
.profile .dropdown {
    padding-right: 30px;
  }
  .menu-Responsive.navbar-toggler {
    position: fixed;
    right: 7px;
    top: 3px;
    border: 0px;
  }
  .mobile-menu {
    background: #aa000d;
    position: absolute;
    top: 5px;
    width: 300px;
    z-index: 9999;
    right: 0;
    height: 100vh;
  }

  .m-auto.navbar-nav {
    padding-top: 10px;
  }
.submenu {
  display:none;
}
  .nav-item {
    width: 100%;
    padding: 7px 20px;
  }

  .header .nav-item a {
    background: #AA000D;
    padding: 6px 50px;
    padding-right: 50px;
    padding-left: 50px;
    border-radius: 6px;
    font-size: 15px;
    display: block;
  }


  .menu-Responsive {
    padding: 0px;
  }
}
.customer-admin-link {
  display: block;

  @media (max-width: 990px) {
    display: none;
  }
}


@media (max-width: 430px) {
.mobile-menu {
  width: 95%;
}
.nav-scroll {
  max-height: 38rem; /* Adjust the height as needed */
  overflow-y: auto;
}
}