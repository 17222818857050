#moveContent.clientWrapper {
  background: url(../../assets/Images/body_bg.jpg) no-repeat center bottom / cover;

  &>div {
    transition: all 0.4s ease;

    &.paneIsOpen {
      width: calc(100% - 430px);
    }

  }

  .sliding-panel-container {
    position: fixed;
    background: #fff8ea;
    left: auto;
    right: -1000px;
    top: 0;
    transition: all 0.4s ease;
    z-index: 2000;
    width: 430px;
    &.active {
      right: 0;
    }

    .group_detailsWrapper {
      height: calc(100vh - 60px);
      overflow: auto;
      padding: 0 14px;

      .bg-light {
        background-color: #ffe7c8 !important;
      }
    }

    .circle-icon {
      height: 25px;
      width: 25px;
   .material-symbols-outlined {
        font-size: 16px;
        color: #fff;
      }
    }

    .circle-icon svg {
      fill: #fff;
      height: 16px;
    }

  }

  .check-block {
    background: #ffe7c8;
    padding: 20px;
  }

  .no_data_height {
    min-height: calc(100vh - 263px);
  }

  .limit img {
    height: 19px;
  }

 img.enabled-client {
    height: 14px;
  }
}

#my-tooltip  {
  z-index:999;
}
.tooltip-info-client {
  padding-left: 2px;
  position: absolute;
  top: 10px;
}
// .user-form .date-picker-error .errorMessage {
//   left: 0;
//   top: 60px;
//   width: 100%;
//   line-height: 11px;
//   position: absolute;
// }
@media (max-width: 991.98px) { 
  #moveContent.clientWrapper .paneIsOpen {
   width: 100%;
 }
 #moveContent.clientWrapper .sliding-panel-container {
   width: 100%;
 }
}