$borderRadius: 20px;
$borderColor: #f2f2f2;

.AdminWrapper {
  .card-body {
    background: #fff8ed;
  }

  .card-header {
    background: #aa000d !important;
    border-top-color: #aa000d !important;

    .card-title {
      color: #fff;
    }
  }

  .blank_height {
    min-height: calc(100vh - 201px) !important;
  }

  .client-bg {
    background-image: url(../../assets/Images/oilClient.jpg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  .bg-gradient-danger {
    background: #d93a3c;
  }

  .card-green {
    background: #b7e9fe;
  }

  .card-purple {
    background: #b4c7ff;
  }

  .circle-total {
    background: #fff;
    height: 60px;
    width: 60px;
    display: flex;
    border-radius: 100px;
    border-color: #f5b296;
    border: 10px solid #f5b296;
  }

  .total-vehicles {
    width: 32px;
    margin: 0 auto;
  }

  .bg-Total-vehicles-brown {
    background: #f19066;
  }

  .bg-Total-drivers-blue {
    background: #778beb;

    .circle-total {
      border: 10px solid #a2b2f2;
    }
  }

  .bg-Total-customer-blue {
    background: #63cdda;

    .circle-total {
      border: 10px solid #95dce5;
    }
  }

  .circle {
    border: 4px solid #eee;
    margin: 0 auto;
    overflow: hidden;
    padding: 20px 0;
    position: relative;
    text-align: center;
    border-radius: 10px;
  }

  .w-100.client-card.card {
    background: #fef1eb;
  }

  .vehicles .rounded {
    border-radius: $borderRadius !important;
    border: 1px solid $borderColor;
  }

  .bg-tanks-brown {
    background: #acecc4 !important;
    border-radius: 20px;
  }

  .bg-gradient-success {
    background: #585f65;
  }

  .bg-plants-transaction {
    background: #8d8bec;
  }

  .bg-plants-delivered {
    background: #05d5d0;
  }

  .clients {
    .client-dashboard {
      width: 100%;
      overflow: hidden;

      img {
        width: 125px;
        float: right;
      }
    }

    .fs-1.fw-bolder.m-0 {
      font-size: 50px !important;
      line-height: 53px;
    }

  }

  .rounded {
    border-radius: 20px !important;
    overflow: hidden;
  }

  .w-20px {
    width: 20px !important;
  }

  .h-20px {
    height: 20px !important;
  }

  .bg-primary-transparent {
    background-color: rgba(0, 165, 162, 0.2);
    color: var(--primary-color);
  }

  .blue-wave {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 228px;
  }

  .green-color {
    color: #7a89f2;
  }

  .purple-color {
    color: #ff7f28;
  }

  .circle-client {
    padding: 10px;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;

    img {
      width: 100%;
      margin: 0 auto;
    }
  }

  .yellowWave {
    width: 100%;
  }

  .cpu-monitoring .card-text {
    overflow: hidden;
  }

  .view-all-client.text-end {
    background: #AA000D;
    border-radius: 6px;
    padding: 4px 10px;
  }

  .selectFilter {
    width: 100px;
  }

  .no_data {
    font-family: oswald;
    display: table; 
    width: 100%;
  }
  .filter {
    cursor: pointer;
  }
  .card-text .list-group-item {
    padding: 2px;
  }
}
.admin-filter .modal-dialog {
  max-width: 500px;
}

@media (max-width: 767px) {
  .AdminWrapper.py-2.px-5 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
 
}

@media screen and (min-device-width: 1190px) and (max-device-width: 1210px) { 
  .super-admin-container {
    padding:0px !important;
    .card-body {
      padding: 5px;
    }
  }
  }