#moveContent {
  background: url(../../assets/Images/body_bg.jpg) no-repeat center bottom / cover;

  &.transactionWrapper {
    .table tbody tr:hover {
      background-color: transparent;
    }

    .sliding-panel-container {
      width: 550px;
      background: #fff8ea;
      top: 0;

      .group_detailsWrapper {
        overflow: auto;
        padding: 0 14px;
        height: calc(100vh - 53px);
      }

      .table {
        background: transparent;

        thead {
          background: transparent;

          th {
            color: #2E3B52 !important;
            font-size: 13px;
          }
        }

        th {
          font-size: 13px;
        }

        td {
          font-size: 13px;
        }

        .amount-due {
          background: #aa000d;
          color: #fff !important;
          padding: 10px;

        }

      }

      .table.table-striped.detail-Table>thead {
        background: #f6efdf;
        color: #000 !important;

        th {
          color: #000 !important;

          background: #f6efdf;
        }
      }

      .table.table-striped.detail-Table {
        border: 1px solid #eee;
        border-bottom: 0px;

        tr {
          border-bottom: 1px solid #eee;
        }
      }
    }

    .paneIsOpen {
      width: calc(100% - 550px);
    }

    .detailTransactionParent div:first-child .detailviewCircle {
      background-color: #d93a3c;
    }

    .detailTransactionParent .detailviewCircle {
      width: 120px;
      height: 120px;
      border-radius: 12px;
    }

    .detailTransactionParent {
      div:first-child .detailviewCircle {
        background-color: #d93a3c;
      }

      div:nth-child(2) .detailviewCircle {
        background-color: #ff7f28;
      }

      div:last-child .detailviewCircle {
        background-color: #585f65;
      }

      .detailviewCircle {
        width: 160px;
        height: 160px;
        margin: 0 auto;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 1.2rem;
        padding: 0 14px;

        img {
          display: block;
          margin: 0px auto;
          width: auto;
          height: auto;
          filter: brightness(0) invert(1);
        }
      }
    }

    .customer.bg-light {
      background-color: #f6efdf !important;
    }
    .no_data_table {
      min-height: calc(100vh - 224px);
  }
    
  }
}


.fuel-tab-table {
  background: #fdf7ec;
  padding: 10px;
}

.tabVIew {
  background: #fdf5f2;

  .table-striped>tbody>tr:nth-of-type(2n+1) {
    --bs-table-accent-bg: #fff8ed;
  }

  .tr.active {
    background: #aa000d;
  }

  .tr.active th,
  .tr.active td {
    color: #fff;
  }

  .TicketNumber {
    text-transform: uppercase;
    text-align: center;

    p {
      margin: 0;
      font-size: 2.2rem;
      font-family: "Oswald";
      letter-spacing: 1px;
      color: #aa000d;
      margin-top: -15px;
    }

    small {
      letter-spacing: 2px;
    }
  }
}

.table>tbody>tr:hover {
  background-color: #ffeccd;
}

.transactions {

  .table-edit-icon .btn-outline-primary {
    background: #AA000D;
    color: #fff;
    border-radius: 6px;
    font-size: 12px;
    padding: 2px 10px;

    .btn-outline-primary :hover {
      background: black;

    }

    svg {
      fill: #fff;
    }

    .transactionTabs .active span.bi.bi-x {
      color: #fff;
    }
  }

  .Fuel-table {
    height: calc(100vh - 273px) !important;
    overflow: hidden;
    border-radius: 7px;
  }

  
  .fuel-tab-table {
    background: #fbf7f0;
    padding: 10px;
  }
.total-transaction {
  width: auto;
  text-align: center;
  margin: -17px auto 0;
  }
  .paneIsOpen .total-transaction {
    margin-top: 0;
  }
}

$black: #000;
$maroon: #aa000d;
$white: #fff;

// .header {
//     background: $maroon;
// }

.fuel-tab-table {
  background: #fffcf5;
  padding: 10px;
}

.form-control {
  height: 38px;
  background: transparent;
  font-size: 12px;
}

.transactionTabs {
  .nav-link.active {
    border-color: #aa000d !important;
    border-radius: 6px;
    border: 1px solid;
    background: #aa000d;
  }

  .nav-link {
    color: $black;
  }

  .nav-link.active span {
    color: $white;
  }

  .nav-link.active svg {
    fill: #fff !important;
  }

  .active svg {
    fill: #000;
  }
}

.customer-modal {
  .TicketNumber {
    text-transform: uppercase;
    text-align: center;
  }

  .TicketNumber small {
    letter-spacing: 2px;
  }

  .TicketNumber p {
    margin: 0;
    margin-top: 0px;
    margin-top: 0px;
    font-size: 1.2rem;
    font-family: "Oswald";
    letter-spacing: 1px;
    color: #aa000d;
    margin-top: 0;
  }
}
/* date picker input box*/
.add-transaction {
  .form-select {
    padding: .375rem 0.45rem .375rem .75rem !important;
  }
  .MuiFormControl-root.MuiTextField-root.form-control.form-select.css-z3c6am-MuiFormControl-root-MuiTextField-root {
    padding: 0px !important;
  }
}
/* csv file import button start*/
.custom-file-button {
 span {
    font-size: 19px;
    padding-right: 4px;
  }
  label {
    background-color: #AA000D;
    cursor: pointer;
    color: #fff;
    border: 0px;
    border-right: 1px solid;
    font-weight: 500;
  }

  input[type="file"] {
    margin-left: -2px !important;
    line-height: 27px;

    &::-webkit-file-upload-button {
      display: none;
    }

    &::file-selector-button {
      display: none;
    }
  }
  .text-danger.errorMessage {
    width: 100%;
    margin-top: 2px;
    font-size: 12px;
  }
}

/* csv file import button end*/

// .add-transaction-area {
//   background: #ffe7c8;
// }

// .add-transaction-are {
//   background: #ffe7c8;
// }

.tanks-apartment {
  border-top: 1px solid #ced4da !important;
}
.tanks-heading {
  color: #AA000D;
  font-weight: 500;
  width: 100%;
  border-bottom: 1px solid #ced4da;
}
.error-text {
  border-top: 1px solid #aa000d78;
  padding-top: 5px;
}
.input-group-text.upload-label {
  height: 38px;
}
.date-range-transaction .datePicker.px-2 {
  padding: 0px !important;
}
.no_data_table.transaction-table {
  min-height: calc(100vh - 238px);
}
.add-transaction select.form-control, select.form-control:focus {
  background: #f6eddc url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHPSURBVHic7dgtSANhGMDx/4YiU5TJwGAQLAaLoBaLFoOYbDabzWjWbrTJgmsmm0EsrlgsFsOCRVAQBEV0m98abgc6N+69u/fj3D0/eMruBu//2MZ4QAghhBBCCCGESJ1MwPVBK6cw7z7Mzb1AEagCXx0y1UZTTuUBFBNwYFOz0xzb/BXIAo94n4JO9AT0/3wh23SD/6RSo9UDKDk4hy27KjflgDLuv6+6p4zijyDAAHCagEPrmjMgrxrvyzfe6PrwceccKISN9w0BlQRERJ0LYDhqvG8EuExATNi5AkbjxvvGgJsERKnOLTCuK943AdwlIC5oHoBp3fG+Gbx/U64j200NmDUV75sHnh2HtppXYNFg9y9LwJvhoDDzDiwbLW5hBfiIeXAd8wmsGm5ta03hgKZn3XhlgE3cxW9Y6FOyhf34bStlijJ42xZb8SWC95nWZYE9zMfvA12WmkLrBg4wF38E9FiriSgHHKM//gTos9gRi+6FSqSFhmsFvGVE3PgK3l7iX4q7UNGy0HAt6kLlGo0LDdfCLlSMLDRcU12oGF1ouBa0UKkBc85OZ8kCUOdvfL1xLRWm8P7VveBtlw6BSacnEkIIIYQQQgghRGp8A7sjgU5M3b7vAAAAAElFTkSuQmCC) no-repeat right 0.75rem center/auto 12px;
}

@media (max-width: 1200px) { 
.transactions .total-transaction {
  margin-top: 0;
}
}
@media (max-width: 991.98px) { 
  #moveContent.transactionWrapper .paneIsOpen {
   width: 100%;
 }
 #moveContent.transactionWrapper .sliding-panel-container {
   width: 100%;
 }
.transactionTabs  .nav-item {
  width: auto;
  padding: 0;
}
}
@media (max-width: 767px) { 
.list-group.list-group-horizontal.total-transaction {
  display: block;
  margin-top: -34px;
}
}
.transactions .Fuel-table {
  height: 100vh;
}
@media (max-width: 600px) { 
#moveContent.transactionWrapper .detailTransactionParent .detailviewCircle {
  margin-bottom: 15px;
}
}
@media (max-width: 500px) { 
.list-group.list-group-horizontal.total-transaction {
  margin-top: 0px;
}
}
@media (max-width: 400px) { 

  .transactionTabs .nav-link {
  padding: .5rem 8px;
}
}