.supplierWrapper {
  .blank_height {
    min-height: calc(100vh - 202px);
  }
  .Fuel-table {
    height: calc(100vh - 256px);
  }

}
  
.supplier-modal .modal-dialog {
  max-width: 600px;
}