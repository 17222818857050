.supportWrapper {
    background-image: url(../../../assets/Images/dots.png);
    background-position: 84% 120%;
    background-repeat: no-repeat;
    background-size: 29% auto;
    padding: 36px 20px;
        .about-image {
    background-color: #fff8f1;
    background-image: url(../../../assets/Images/about.jpg);
    margin: -2% 33% 0% 0%;
    background-position: center center;
    background-size: cover;
    border-radius: 300px 300px 10px 10px;
    height: 200px;
    width: 100%;
  img {
    border-radius: 300px 300px 300px 300px;
    width: 125px;
    margin: 9% 3% 4% -60px;
  }
  
}
.circle {
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 100%;
  .material-symbols-outlined {
    font-size: 14px;
    vertical-align: middle;
    line-height: 24px;
  }
}
  .version {
  padding-top: 2px;
}

}

.modal-about .modal-dialog {
    width: 700px;
    max-width: 800px;
  }
  .logo-support img {
    width: 154px;
  }
  @media (max-width: 767px) { 
  .modal-about .modal-dialog {
    width: 96%;
  }
}