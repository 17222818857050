$maroon: #AA000D;
$black: #000;
$white: #fff;
$blueText: #2E3B52;
$purple: #845ADF;

.btn-btn-primary {
  background: #AA000D !important;
}
.editButton a {
  color: #fff;
}

.file-upload {
  background: #ffe7c8;
  padding: 10px 10px 0 10px;
  margin-bottom: 10px;
}

.upload-size-image {
  align-items: center;
  border: 1px dashed #AA000D;
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  height: 60px;
  justify-content: center;
  width: 60px !important;
  overflow: hidden;
}

.user-profile-box {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.upload-profile {
  display: table-cell;
  position: absolute;
  top: 21%;
  left: 18px;
}

.form-check-input:checked {
  background-color: $maroon;
  border-color: $maroon;
}

.Fuel-table {
  .sorting-head .material-symbols-outlined {
    position: absolute;
    font-size: 14px;
    top: 11px;
  }
}

.edit-icons .edit-text {
  font-size: 14px;
}

.edit-icons .material-symbols-outlined.edit-list {
  font-size: 22px;
  line-height: 22px;
  float: left;
  margin-right: 6px;
}

.tableFooter {
  small {
    font-size: 12px;
  }

  select {
    border: 0px;
    font-size: 12px;
    background: #eee url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHPSURBVHic7dgtSANhGMDx/4YiU5TJwGAQLAaLoBaLFoOYbDabzWjWbrTJgmsmm0EsrlgsFsOCRVAQBEV0m98abgc6N+69u/fj3D0/eMruBu//2MZ4QAghhBBCCCGESJ1MwPVBK6cw7z7Mzb1AEagCXx0y1UZTTuUBFBNwYFOz0xzb/BXIAo94n4JO9AT0/3wh23SD/6RSo9UDKDk4hy27KjflgDLuv6+6p4zijyDAAHCagEPrmjMgrxrvyzfe6PrwceccKISN9w0BlQRERJ0LYDhqvG8EuExATNi5AkbjxvvGgJsERKnOLTCuK943AdwlIC5oHoBp3fG+Gbx/U64j200NmDUV75sHnh2HtppXYNFg9y9LwJvhoDDzDiwbLW5hBfiIeXAd8wmsGm5ta03hgKZn3XhlgE3cxW9Y6FOyhf34bStlijJ42xZb8SWC95nWZYE9zMfvA12WmkLrBg4wF38E9FiriSgHHKM//gTos9gRi+6FSqSFhmsFvGVE3PgK3l7iX4q7UNGy0HAt6kLlGo0LDdfCLlSMLDRcU12oGF1ouBa0UKkBc85OZ8kCUOdvfL1xLRWm8P7VveBtlw6BSacnEkIIIYQQQgghRGp8A7sjgU5M3b7vAAAAAElFTkSuQmCC) no-repeat right .20rem center/auto 10px;
    padding-right: 30px;
    appearance: none
  }
}


.btn-outline-primary {
  border-color: $maroon;

  svg {
    fill: $maroon;
  }
}

.btn-outline-primary:hover {
  background-color: transparent;
  border-color: $black;

  svg {
    fill: $white;
  }
}


.btn-outline-primary:focus svg {
  fill: $white;
}

.btn-outline-primary:active:focus {
  box-shadow: none;
}

// .table-edit-icon {
//   width: 55px;
// }



.table-edit-icon .btn-outline-primary {
  padding: 3px 4px;
}

.call .fs-6 {
  font-size: 13px !important;
  line-height: 26px;
}

.userWrapper {
  .blank_height {
    min-height: calc(100vh - 175px);
  }

  .status {
    right: -4px;
  }

  .user-content {
    padding-left: 19px;
  }

  .card {
    background: #ffeccd;
    padding: 0px !important;
    overflow: hidden;

    .user-list {
      background: #fff8ed;
      position: relative;

      .editButton {
        margin-top: -2px;
      }
    }
  }

  .Fuel-table {
    height: calc(100vh - 252px);
    overflow: hidden;
    border-radius: 7px;
  }

  .table-striped>tbody>tr:nth-of-type(even) {
    --bs-table-accent-bg: #f1daa3;
    border-color: #f1daa3;
  }

  .table>thead {
    background: #af9763;
  }

  button.btn.btn-outline-primary.btn-sm {
    background: #af9763;
    margin: 0 2px;
    border-color: #af9763;
  }

  .btn-outline-primary svg {
    fill: #fff;
  }

  .avatar_user {
    align-items: center;
    background-color: #0000;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    font-weight: 500;
    font-size: 24px;
    line-height: 80px;
    margin: 0;
    justify-content: center;
    line-height: 34px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    width: 70px;
    height: 70px;
    border: 6px solid #eee;
  }

  .avatar_user>img {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .arrow-top {
    position: absolute;
    width: 30px;
    left: 0;
    top: 0;
  }

  .arrow-bottom {
    position: absolute;
    width: 30px;
    right: 121px;
    bottom: 0;
  }

  img.arrowLeaf {
    width: 150px;
    height: 150px;
  }

  .circle-icon {
    height: 25px;
    width: 25px;

    svg {
      fill: #fff;
      height: 15px;
      width: 14px;
    }
  }

  .enable-caret .dropdown-toggle::after {
    display: none;
  }


  .user-name {
    font-size: 17px !important;
    margin-top: 0px !important;
    font-weight: 700;
    color: #AA000D;
  }

  .call {
    padding: 10px;
    border-top: 1px solid #ffd086;
    background: #ffeccd;
    border-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    svg {
      cursor: default;
    }
  }

  .avatar_user.mb-3.shadow:hover {
    transform: scale(1.1);
    transition: all 0.3s linear;
  }

  .editButton .a:hover {
    background: #eee;
  }

  .editButton .dropdown-menu svg {
    fill: #fff;
  }

  .editButton .dropdown-menu {
    min-width: 8rem;
    background-color: $maroon;
    z-index: 9999;
  }

  #dropdown-basic svg {
    fill: #747474;
  }

}


.section-user .badge {
  color: #fff;
  width: auto;
  font-size: 12px;
  border-radius: 6px;
}

.text-bg-warning {
  background-color: #fc8f0b;
}



textarea.form-control {
  min-height: calc(1.5em + (.75rem + 2px));
  height: 82px;
  resize: none;
}

.user-modal .modal-dialog {
  max-width: 700px;
}

.user-modal .modal-dialog .hide-password.material-symbols-outlined {
  top: 30px !important;
}



.section-user {
  .dropdown-menu .dropdown-item {
    color: #fff;
    padding: 4px 12px;
  }

}

.upload-icon-image {
  //background-color: rgb(170, 0, 13);
  background-color: rgba(170, 0, 13, 0.1);
  position: absolute;
  width: 100%;
  bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;

  span.material-symbols-outlined {
    // color: #fff;
    text-align: center;
    font-size: 16px;
  }
}

.badge {
  text-transform: uppercase;
  font-size: 10.5px;
  line-height: 12px;
  padding-bottom: .25rem;
  padding-top: .25rem;
}

.badge-primary {
  background-color: #007bff;
  text-transform: uppercase;
}

.badge-success {
  background-color: #28a745;
}

.badge-customer-admin {
  background-color: rgb(220, 53, 69)
}

.badge-customer-user {
  background-color: rgb(255, 152, 0)
}

.badge-client-user {
  background-color: #17a2b8;
}

.badge-client-admin {
  background-color: rgb(0, 123, 255);
}

.badge-super-admin {
  background-color: rgb(40, 167, 69);
}

.userAdmin img {
  height: 12px;
}

.tooltip-info {
  position: absolute;
  top: -6px;
  padding-left: 2px;
}

.height-page,
.blank_height,
.background-image,
footer {
  background: url(../../assets/Images/body_bg.jpg) no-repeat center bottom / cover;
}

@media (max-width: 576px) {
  .user-type.col {
    flex: 1 0 70%;
  }
}