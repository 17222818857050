$maroon: #AA000D;
$white:#fff;
$black:#000;
$purple: #845ADF;
$blue: #49B6F5;
$pink:#FFE2E5;
$title:#30363B;
$gry: #425166;
$brown:#FFF4DE;
$lightGreen:#DCFCE7;


// .logo {
//   font-family: Algerian;
// }
.filter__indicator.filter__dropdown-indicator {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}
.filter__single-value {
  color: #fff !important;
}
.custom-select {
  z-index: 9;
  background: #fc8f0b;
  color: #fff;
  border-radius: 6px;
  width: auto;
  height: 36px;
  line-height: 29px;
}
// .filter {
//   &__menu {
//     margin: 0.125rem auto;
//   }

// }
.header {
  svg {
    cursor: default;
  }
.btn:not(.btn-sm) {
    padding: 8px;
  }
    background: $maroon;

.btn {
    border-radius: 7px;
}
.dropdown-toggle::after {
    color: $white;
    display: none;
  }
.active.nav-link {
    background:$white;
    border-radius: 7px;
    color:$black !important;
  }
  .btn.btn-dark {
    padding: 3px 9px !important;
  }
  small {
    font-size: 13px;
  position: sticky;
  z-index: 99 !important;
  }
  .navbar-light .navbar-text {
    display: flex;
    align-items: center;
  }
  .profile .dropdown-menu {
    min-width: 20rem;
    z-index: 9999;
  }
  .notification  .dropdown-menu {
    min-width: 20rem;
  }
  .language img {
    width: 15px;
    height: 15px;
  }
  .dropdown-item {
    font-size: 14px;
  }
  .avatar.avatar-50px > img {
    width: 45px;
    height: 45px;
    margin-top: 2px;
  }

  .profile-header {
    padding: 10px 15px;
    background: #fff8ed;

  }
  .font-13 {
    font-size: 12px;
    line-height: 12px;
  }
  .profile {
    .btn-btn-secondary {
      border: 1px solid #AA000D;
      color: #AA000D;
    }
  
    .btn {
      padding: 5px 10px !important;
    }
    .profile-dropdown {
      padding-left: 0px !important;
    }
  }
  .bg-body-tertiary.pt-0.navbar.navbar-expand-lg.navbar-light {
    margin-top: -16px;
  }
  .logo {
    // font-family: Algerian;
    position: relative;
 img {
  width: 52px;
  position: absolute;
  left: 0;
  top: -15px;
    }
    .logo_text {
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      left: 38px;
      top: -1px;
    }
  }
  .profile-name {
    word-break: break-all;
  }

}
.dropdown-menu {
  border-radius: .475rem;
  animation: menu-sub-dropdown-animation-fade-in .3s ease 1,menu-sub-dropdown-animation-move-up .3s ease 1;
}
.profile .dropdown-item:focus,   .profile .dropdown-item a:hover {
  color: $maroon !important;
  background-color: transparent;
  transition: color .2s ease;
}
.profile-area .dropdown-item:focus, .profile-area .dropdown-item:hover {
  background-color: inherit;
}
.w-md-40px {
  width: 40px !important;
}
.h-md-40px {
  height: 40px !important;
}

.h-30px {
  height: 30px !important;
}
.w-30px {
  width: 30px !important;
}
.h-20px {
  height: 20px !important;
}
.w-20px {
  width: 20px !important;
}
.avatar.h-md-40px.h-30px.me-3 {
  margin-top: -15px;
}

.notification small {
  font-size: .75rem;
}
.text-muted {
  color:$gry;
}

.fs-10 {
  font-size: 10px !important;
}
.notification .card-body:hover {
  background: #f3f3f3;
}

 .filter .dropdown-menu {
  min-width: 20rem;
}
.fw-normal.text-muted.oneDay {
  padding-left: 6px;
}
.fs-12 {
  font-size: 12px;;
}
.profile-area{
  .dropdown-item:hover {
    background: #eee;
  }
  .badge {
    font-size: 11px;
  }
 small {
    font-size: 11px;
  }
}



.FuelBanner-background {
  background-image: url(../../assets/Images/dashboardBanner.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  margin-top: 55px;
}

.addbutton{
  background-color: $purple;  
}
.addbutton:hover{
  background-color: $maroon;
  box-shadow: inset 0 0 0.1rem 0 black;
  
}
.welcome-area p {
  display: block;
  font-size: 12px;
  line-height: 0.5;
  letter-spacing: 1px;
  color: #ffe7c8;
  text-align: right;
}
.welcome-area a {
  font-size: 14px !important;
  display: block;
  width: 88px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
  text-decoration: none;
  text-align: right;
}
@media (max-width: 1300px) {
.header .bg-body-tertiary.pt-0.navbar.navbar-expand-lg.navbar-light {
  margin-top: -15px;
}
}
@media (max-width: 1200px) {
.header .nav-item a {
  font-size: 13px;
}
.FuelBanner-background {
  margin-top: 52px;
}
}
@media (max-width: 991.98px) {
  .header .nav-item a {
    font-size: 15px;
  }
  .welcome-area a {
    width: auto;
  }
  .header .bg-body-tertiary.pt-0.navbar.navbar-expand-lg.navbar-light {
    margin-top: 0;
  }
    .FuelBanner-background {
      margin-top: 48px;
    }
    .navbar-toggler:focus {
      box-shadow: none;
      border: 0px;
  }
.header .profile {
    margin-right: 38px;
  }
  .header .logo  {
    img {
      top: -16px;
      width: 46px;
    }
    .logo_text {
      top: -4px;
    }
 
}


}
@media (max-width:810px) {
  .text-hidden-m {
    font-size: 0px !important;
  }
}
@media (max-width:767px) {
  .customerWrapper .address.ellipse-address {
    min-height: auto;
    max-height: 100%;
  }
.nav-item {
  width: 100%;
  padding: 10px;
}
.navbar-toggler:focus {
  box-shadow: none;
  border: 0px;
}

}
@media (max-width:600px) {
.dropdown-menu-end.dropdown-mobile[data-bs-popper] {
  right: -45px;
  left: auto;
}
}
@media (max-width:500px) {
  .datepicker-custom .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
  .datepicker-custom .rdrDateRangePickerWrapper {
    display: block !important;
    width: 100% !important;
}
  .datepicker-custom .rdrCalendarWrapper {
    width: 100%;
  }

.circle-icon-m {
  height: 35px;
  width: 35px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.circle-icon-m {
  padding: 0px !important;
  svg {
    cursor: pointer;
    margin: 0 auto;
  }
}
}