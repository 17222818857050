$maroon: #aa000d;
$white: #fff;
$black: #000;
$purple: #845adf;
$blue: #49b6f5;
$pink: #ffe2e5;
$title: #5e5c5c;
$subheading: #151d48;
$gry: #425166;
$brown: #fff4de;
$lightGreen: #dcfce7;
$yellow: #f5b849;
$green: #26bf94;
$borderRadius: 20px;
$borderColor: #f2f2f2;
$borderBrown: #f2f2f2;
$borderBlue: #f2f2f2;
$borderGreen: #f2f2f2;
$size: 150px;
$size12: .75rem;
$border: 60px;


.banner-nav .circle-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}




.dashboard {
  background-color: #fff8f1;
  background: url(../../assets/Images/dashboard_bg1.jpg) no-repeat fixed center bottom / cover;

  .vertical_tank_progress {
    position: relative;
    width: 105px;
    // margin: 0 auto;
    height: 207px;

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(../../assets/Images/tank_level.png) no-repeat center / cover;
      content: '';
      top: 0;
      left: 0;
    }

    .progress-bar-vertical {
      width: 90px;
      min-height: 200px;
      display: flex;
      align-items: flex-end;
      position: relative;

      .progress-bar {
        width: 100%;
        height: 0;
        -webkit-transition: height 0.6s ease;
        -o-transition: height 0.6s ease;
        transition: height 0.6s ease;
      }
    }

    .term-list {
      display: flex;
      border: 0 none;
      margin-top: 0px;
      flex-direction: column;
      justify-content: space-around;
      position: absolute;
      right: -7px;
      top: 9px;
      bottom: 13px;

      .list-group-item {
        margin-top: 0px;
        width: 10px;
        height: 1px;
        background-color: #999;
        padding: 0;

        &:last-child,
        &:first-child {
          width: 15px;
        }
      }
    }
  }

  footer {
    background: transparent;
  }

  .rounded {
    border-radius: 20px !important;
  }

  .bg-pink {
    background-color: #fff4f0;
  }

  .card-body {
    padding: 1rem 2rem;
    background: #fff8ed;
  }

  .card-title {
    color: $white;
  }

  .card-header {
    padding: 1rem;
    background: $maroon !important;
    border-top-color: $maroon !important;

    img.filter {
      cursor: pointer;
    }
  }

  .circle {
    height: 210px;
    width: 210px;
    border-radius: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 20px 0;
    overflow: hidden;
    position: relative;
    border: 4px solid #eee;

    &:hover {
      transform: scale(1.1);
      transition: all 0.3s linear;
    }
  }

  .bg-gradient-danger {
    background: #d93a3c;
  }

  .bg-gradient-info {
    background: #ff7f28;
  }

  .bg-gradient-success {
    background: #585f65;
  }
  .bg-gradient-warning {
    background: #218838;
  }
  .bg-gradient-light {
    background: #e0a800;
  }
  .bg-gradient-blue {
    background: #138496;
  }
  
  .fs-3,
  .text-black {
    color: $subheading;
  }

  .fs-6 {
    color: $gry;
    font-weight: 500;
  }

  small {
    color: $gry;
    font-size: 11px;
  }

  .vehicles .rounded {
    border-radius: $borderRadius !important;
    border: 1px solid $borderColor;
  }

  .bg-vehicles-pink {
    background: #feecec;
  }

  .bg-drivers-blue {
    background: #ecedff;
    border-radius: $borderRadius;
  }

  .bg-plants-blue {
    background: #F2C99E;
    border-radius: $borderRadius;
  }

  .bg-tanks-brown {
    background: #acecc4 !important;
    border-radius: $borderRadius;
  }

  .yellow {
    background-color: $yellow;
  }

  .black {
    background-color: $black;
  }

  .maroon {
    background-color: $maroon;
  }

  .card {
    overflow: hidden;
  }

  .no-data-chart {
    height: 310px;
    display: table;
    width: 100%;
  }

  .no-chart-content {
    display: table-cell;
    vertical-align: middle;
    font-family: Oswald;

    img {
      width: 260px;
    }
  }

  .no_data_table.no-tanks {
    min-height: 100%;

    .NOData {
      width: 144px;
    }
  }

  .NoAlarm {
    width: 200px;
    margin: 0 auto;
    align-items: center;
    font-family: Oswald;
  }

  .No-data-alarm {
    height: 54vh;
    align-content: center;
    font-family: Oswald;
  }

  .NOFliter {
    width: 100px;
  }

}

.NODataFilter {
  width: 100px;

}

.No-data-filter {
  font-family: Oswald;
}

.dashboard-full-screen {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: -33px;
}

.miniMizeIcon {
  position: fixed;
  right: 11px;
  top: 6px;
  z-index: 9999;
}


.calenderButton {
  position: absolute;
  bottom: 10px !important;
  right: 0;
}

.transaction-block {
  width: 305px;
  height: auto;
  margin: 0 auto;
  margin-top: 0px;
  position: relative;

  .transaction-circle {
    display: inline-block;
    line-height: 18px;
    border-radius: 50%;
    color: white;
    margin: 0;
  }

  a {
    box-shadow: 0 0.2rem 1rem rgb(243, 219, 220) !important;
    border: 5px solid #eee;
    width: 17em;
    height: 17em;
    text-align: center;
    background: url(../../assets/Images/pinkCircle.svg) no-repeat center / cover;

    &:nth-child(1) {
      z-index: 1;
      background-color: #EFBC9B;
      width: 12em;
      height: 12em;
    }

    &:nth-child(2) {
      z-index: 2;
      width: 14em;
      height: 14em;
      background-color: #9fdfa6;
      right: -10px;
      position: absolute;
    }

    &:nth-child(3) {
      z-index: 1;
      background-color: #C9D3EA;
      transform: translateX(4em) translateY(-3em);
    }

    &.circle-green {
      background-color: #891652;
    }

    &.circle-red {
      background-color: #240A34;
    }

    &.circle-purple {
      background-color: #EABE6C;
    }

  }

  .total-Transaction {
    flex-flow: column;
  }

}

.timeline-circle-wrap {
  height: 100%;
  width: 100%;
  position: relative;

  .timeline-circle {
    height: 25px;
    width: 25px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;

    // &::before {
    //   content: "";
    //   width: 1px;
    //   height: calc(100% + 29px);
    //   background-color: rgb(238, 238, 238) !important;
    //   display: block;
    //   position: absolute;
    //   top: -15px;
    //   left: 12px;
    //   z-index: -1;
    // }

    .material-symbols-outlined {
      font-size: 15px;
      cursor: default;
    }
  }


}
.circle-repeat:nth-child(2) .blue {
  background: #891652;
}
.circle-repeat:nth-child(3) .blue {
  background: #240a34;
}
.circle-repeat:nth-child(4) .blue {
  background: #eabe6c;
}
.circle-repeat:nth-child(5) .blue {
  background: #58805c;
}

// .card .card-body .timeline-circle-wrap .timeline-circle::before {
//   background-color: rgba(255, 255, 255, 0.1);
//   top: 25px;
// }

// .card .card-body .timeline-circle-wrap .timeline-circle::after {
//   background-color: rgba(255, 255, 255, 0);
//   top: 0px;
// }

.timeline-list {
  small {
    font-size: 12px;
  }

  .list-group-item>div>div {
    padding-left: 6px;
  }

  .col.pl-0 {
    padding-left: 45px !important;
  }
}

.filledCircle .rounded-circle {
  border-radius: 50% !important;
  width: 10px;
  width: 10px;
  background-color: transparent !important;
  border: 5px solid #9e030c;
}

.emptyCircle .rounded-circle {
  border: 5px solid #e2e3e5;
}

.TruckStatus {
  height: 25px;
  width: 25px;
}

.truck_circle {
  background: #fff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;

  img {
    height: 15px;
    width: 15px;
    text-align: center;
    margin: 0 auto;
  }
}


.truck-status-card {
  position: relative;
  padding: 10px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: #919eab4d 0 0 2px, #919eab1f 0 12px 24px -4px;

  h3 {
    font-size: 26px;
    margin-bottom: 8px;
  }

  .indicatorACtive {
    font-size: 10px;
  }

  h6 {
    position: absolute;
    bottom: 0;
  }


}


.offline-truck {
  border-left: 2px solid #000;
  background-color: rgba(223, 220, 220, 0.25);

}

.online-truck {
  border-left: 2px solid #43b97f;
  background: #e7fadf;
}



@media (max-width: 768px) {
  .container {
    max-width: 100% !important;
  }

  .dashboard.py-2.px-5 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .col-lg-2.col-sm-2.col-xs-2.timeline-width {
    width: auto;
  }
  .col-lg-10.col-sm-10.col-xs-10.timeline-width {
    width: 85%;
  }

}





.multi-select-dropdown.dashboard-filter-all button {
  width: 100%;
  border: 1px solid #eee !important;
  text-align: left !important;
  height: 38px;
  padding: .075rem .75rem !important;
}

/*fuel status filter start css*/
.dashboard-filter-all {
  .dropdown-menu {
    width: 100%;
    padding: 10px;
  }
}

.fuel-filter {

  .table {
    border: 1px solid #eee;
    margin: 0px;

    tr:nth-of-type(2n+1) {
      --bs-table-accent-bg: #fff;
    }

    thead {
      background: transparent;
    }

    th {
      color: #2E3B52 !important;
      font-weight: normal;
    }

    th:first-child {
      width: 32px;
    }

    td:first-child {
      width: 32px;
    }

  }

  .form-group.table-search {
    position: relative;

    input {
      width: 100%;
      height: 29px;
      border-radius: 6px;
      border: 1px solid #eee;
      margin-bottom: 5px;
    }

    .search {
      height: 20px;
      position: absolute;
      right: 5px;
      top: 5px;
    }

  }

}

.dashboard-filter-all {
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-toggle {
    background: #fbf6f2 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHPSURBVHic7dgtSANhGMDx/4YiU5TJwGAQLAaLoBaLFoOYbDabzWjWbrTJgmsmm0EsrlgsFsOCRVAQBEV0m98abgc6N+69u/fj3D0/eMruBu//2MZ4QAghhBBCCCGESJ1MwPVBK6cw7z7Mzb1AEagCXx0y1UZTTuUBFBNwYFOz0xzb/BXIAo94n4JO9AT0/3wh23SD/6RSo9UDKDk4hy27KjflgDLuv6+6p4zijyDAAHCagEPrmjMgrxrvyzfe6PrwceccKISN9w0BlQRERJ0LYDhqvG8EuExATNi5AkbjxvvGgJsERKnOLTCuK943AdwlIC5oHoBp3fG+Gbx/U64j200NmDUV75sHnh2HtppXYNFg9y9LwJvhoDDzDiwbLW5hBfiIeXAd8wmsGm5ta03hgKZn3XhlgE3cxW9Y6FOyhf34bStlijJ42xZb8SWC95nWZYE9zMfvA12WmkLrBg4wF38E9FiriSgHHKM//gTos9gRi+6FSqSFhmsFvGVE3PgK3l7iX4q7UNGy0HAt6kLlGo0LDdfCLlSMLDRcU12oGF1ouBa0UKkBc85OZ8kCUOdvfL1xLRWm8P7VveBtlw6BSacnEkIIIYQQQgghRGp8A7sjgU5M3b7vAAAAAElFTkSuQmCC) no-repeat right .75rem center/auto 12px;
    height: 38px;
    padding-right: 30px;
  }

  .please-select {
    margin: 0px;
    line-height: 24px;
    padding: 7px 0;
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .transaction-block {

    a {
      box-shadow: 0 0.2rem 1rem rgb(243, 219, 220) !important;
      border: 5px solid #eee;
      width: 17em;
      height: 17em;
      text-align: center;
      background: url(../../assets/Images/pinkCircle.svg) no-repeat center / cover;

      &:nth-child(1) {
        transform: translate(-2rem, 0rem);
        width: 14em;
        height: 14em;
      }

      &:nth-child(2) {
        z-index: 2;
        transform: translate(2rem, 0rem);
        width: 16em;
        height: 16em;
      }

      &:nth-child(3) {
        transform: translateX(4em) translateY(-4em);
      }

    }

  }
}

@media (max-width: 576px) {
  .dashboard .vertical_tank_progress {
    margin: 0 auto;
  }

  .filledCircle {
    margin: 0 auto;
    text-align: center;
    width: 139px;
  }

  .Tank-background-brown .mt-3.fs-6.fw-semi-bold {
    text-align: center;
  }

  .Tank-background-brown .col-sm-6 {
    order: 1;
    display: flex;
    margin-bottom: 23px;
  }

  .Tank-background-brown .col-sm-6:nth-child(2) {
    order: 2;
  }

  .Tank-background-brown .col-sm-6:nth-child(4) {
    order: 3;
  }


}

@media (max-width: 410px) {
  .transaction-block {
    width: 100%;
  }

  .transaction-block {

    a {
      box-shadow: 0 0.2rem 1rem rgb(243, 219, 220) !important;
      border: 5px solid #eee;
      width: 17em;
      height: 17em;
      text-align: center;
      background: url(../../assets/Images/pinkCircle.svg) no-repeat center / cover;

      &:nth-child(1) {
        transform: translate(0rem, 0rem);
        width: 12em;
        height: 12em;
      }

      &:nth-child(2) {
        z-index: 2;
        transform: translate(0rem, 0rem);
        width: 14em;
        height: 14em;
      }

      &:nth-child(3) {
        transform: translateX(4em) translateY(-4em);
        width: 16em;
        height: 16em;
      }

    }

  }

}


/*fuel status filter css end*/