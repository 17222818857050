$maroon: #AA000D;
$black:#000;
$white:#fff;
$blueText: #2E3B52;
$purple:#845ADF;


.profile_user {
    justify-content: space-evenly;
    background-size: calc(100% - 8rem) calc(100% - -0.5rem);
    background-repeat: no-repeat;
    background-image: url("../../assets/Images/userProfile/pcb.png");
    padding: 30px 0;

    .circle-icon {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    
      svg {
        vertical-align: middle;
        height: 17px;
        width: 17px;
        fill: #fff;
        cursor: default;
      }
    }
    .edit-profile.circle-icon svg {
      width: 13px;
      height: 13px;
    }
    .user-name {
      word-break: break-all;
    }
    .material-symbols-outlined {
      cursor: default;
  font-size: 19px !important;
  color: #fff !important;
    }
  }

  
  .profile-ring {
    display: inline-block;
    position: relative;
    animation: float 2.7s ease-out infinite alternate;
  }
  
  .profile-ring:before,
  .profile-ring:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
  }
  
  .profile-ring:before {
    width: 100%;
    height: 100%;
    border: 3px solid $maroon;
    animation: pulse 2s infinite;
  }
  
  .profile-image {
    width: 10rem;
    height: 10rem;
    overflow: hidden;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-0.8rem);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.94);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0);
    }
    100% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(115, 128, 4, 0.4);
    }
  }
  

  .profile-user.position-relative {
    padding: 30px;
  }

  .profile-content {
    text-align: left;
    margin-top: 12px;
  }

  .modal-profile .modal-dialog {
    max-width: 700px;
  }
  .offline {
    background-color: #ccc;
  }
  .online {
    background-color: #2ca87f;
  }
  .status {
    border: 2px solid #fff;
    border-radius: 50%;
    bottom: -2px;
    display: inline-block;
    height: 15px;
    margin: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    position: absolute;
    right: -10px;
    width: 15px;
  }

.inactive {
    background: #eee;
    color: #999595;
  }
  .active {
    background-color: #2ca87f;
  }
  .edit-profile {
    cursor: pointer;
    svg{
      cursor: pointer;
    }
  }

  @media (max-width:800px) {
  .modal-profile .modal-dialog {
    max-width: 98%;
  }
}
  @media (max-width:767px) {

  .user-profile-popup {
    padding: 0;
  }
  .profile-image {
    width: 100px;
    height: 100px;
  }
  .profile-user {
    padding: 0px 5px !important;
    top: 15%;
  }
  .profile-address.pe-4.pt-3 {
    padding: 0px !important;
  }
}