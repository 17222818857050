.spinner-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 20%);
    z-index: 1000;
    cursor: default;
}

.spinner-loader {
    left: 48%;
    top: 40%;
    position: absolute;
}

.spinner-loader img {
    width: 100px;
}