$maroon: #AA000D;
$gry: #606C77;
$black: #000;
$title: #30363B;
$lightGry:#A2B1BF;

.login-bg {
  background-image: url(../../assets/Images/loginBG.png);
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
}
::placeholder {
  color: $lightGry;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: $lightGry;
}

.loginFrom {
  width: 400px;
  border-radius: 20px;
  position: fixed;
  right: 100px;

  .fs-5 {
    color: $lightGry;
    font-size: 18px !important;
  }
  .fs-4 {
    color: $gry;
    font-size: 16px !important;
  }

  .form-group {
    position: relative;

    input {
      width: 100%;
      padding: 3px 10px 3px;
      font-size: 12px;
      color: $black;
      letter-spacing: 0.062rem;
      border-radius: 0px;
      text-align: left;
      line-height: 17px;
      border-radius: 12px;
      height: 40px;
      background: #F8F8F8;
      border: 1px solid #C7D0D8;
    }

  }

  .btn-primary {
    height: 40px;
    border-radius: 12px;
  }

  .hide-password {
    right: 17px !important;
    top: 11px !important;
  }

  .iconInputBox {
    position: absolute;
    top: 8px;
    right: 7px;
  }

  .text-danger.errorMessage {
    font-size: 12px;
  }

  .forgot-password a {
    font-size: 13px;
  }

  .iconInputBox svg {
    fill: $lightGry;
    cursor: pointer;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
 
}
.logout-modal.modal-dialog {
  max-width: 380px;
}


input:focus {
outline:none;
}

.account-footer a {
  color: #AA000D !important;
  cursor: pointer;
}

@media (max-width: 576px) { 
.loginFrom {
  width: 98%;
  right: inherit;
}
}

.forgetPas p a{
  cursor: pointer;
  color: black !important;
  font-size: 1rem;
  margin-top: 10px;
}
