 .confirmation-modal {
    max-width: 380px;
}

.confirmation-modal table th:first-child {
    width: 80px;
}

.confirmation-modal .Fuel-table .table {
    margin-top: -5px;
}

.popup-dialog {
    z-index: 9999;
  }

.dialog-header {
    background-color:#AA000D;
    color: white;
    
  }
  .icons8-close {
    cursor: pointer;
  } 