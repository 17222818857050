$maroon: #AA000D;
$gry: #C7D0D8;
$title: #30363B;
$subtitle: #606C77;
$purple: #845ADF;
$blue: #49B6F5;
$green: #2ca87f;
$yellow: #F5B849;
$red: #FC6767;
$lightPink: #FFE2E5;
$brown: #AF9763;
$lightBrown: #FFF4DE;
$lime: #17DA13;
$LightLime: #DCFCE7;
$creamColor: #F1DAA3;
$black: #000;
$label: rgb(33, 33, 33);
$white: #fff;


html {
  font-size: 13px;
}

.fw-semi-bold {
  font-weight: 600;
}


/* button style */
.btn-danger {
  color: #fff;
  background-color: #881f1e;
  border-color: #881f1e;
}

.modal-dialog .btn-primary {
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 10rem;
  color: $white;
  border-color: $maroon !important;
  background: $maroon !important;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $maroon;
    border-color: $maroon;
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($black, 15%);
    border-color: darken($black, 15%);
    transition: all .3s;
    z-index: -1;
  }

  &:hover {
    color: $white;
    border-color: $maroon !important;
    background: transparent !important;

    &:before {
      width: 100%;
    }
  }
}

.btn-light {
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  background-color: rgb(51, 51, 51) !important;
  border-color: rgb(51, 51, 51);
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(51, 51, 51) !important;
    border-color: rgb(51, 51, 51);
    z-index: -2;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $maroon;
    border-color: $maroon;
    transition: all .3s;
    z-index: -1;
  }

  &:hover {
    color: $white;
    border-color: $maroon !important;
    background: transparent !important;

    &:before {
      width: 100%;
    }
  }
}

.btn-check:focus+.btn-light,
.btn-light:focus,
.btn-light:focus,
.btn-light:active,
.btn-primary:focus {
  border-color: #aa000d;
  box-shadow: 0 0 0 .25rem rgba(170, 0, 13, .5);
  color: #fff;
}

/*table style*/
.table-edit-icon .btn-primary {
  z-index: 0;
}

.editButton a:hover {
  background: transparent;
}

/* dropdown menu*/
.dropdown-menu {
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  animation: 0.3s ease-in-out 0s normal forwards 0.3s fade in;

  .dropdown-item {
    border-radius: 8px;
    padding: 10px 15px;
  }

}

/*modal*/
.modal {
  z-index: 9999;
}

.modal-backdrop {
  z-index: 9999;
}

.modal-content {
  background-color: #fbf6f0;
}

.modal-password .modal-dialog {
  max-width: 400px;
}

.update-transaction-modal .modal-dialog {
  max-width: 1200px;
}

.add-user-modal .modal-header {
  background: #aa000d;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;

  .material-symbols-outlined {
    color: #fff !important;
    font-size: 20px !important;
    margin-top: 3px;
  }

  .modal-title.h4 {
    font-size: 16px;
  }
}

.confirmation-modal.modal-delete {
  max-width: 420px;
}

/*date picker*/
.rdrDefinedRangesWrapper {
  width: 180px;
}

.datePicker .btn.btn-btn-secondary:hover {
  background-color: #212529 !important;
  border-color: #eee !important;
  color: #fff;
}

.datePicker .dropdown-toggle::after {
  display: none;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  height: 38px !important;
  color: #2e3b52 !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-readOnly {
  background-color: #d1d1d1 !important;
  cursor: not-allowed;
}

.MuiOutlinedInput-root:focus {
  border-color: #F3F3F3 !important;
}

.border-light {
  border-color: #F3F3F3 !important;
}

body {
  font-family: 'Montserrat', sans-serif !important;

  svg {
    cursor: pointer;
  }
.calenderButton .btn{
  text-transform: uppercase;
}
  .btn:not(.btn-sm) {
    font-size: 14px;
    line-height: 1;
    text-align: center;
    border-radius: 6px;
    padding: 8px 27px;

  }

  .modal-footer .btn:not(.btn-sm) {
    padding: 8px 27px;
  }

  footer {
    z-index: 9999;
    position: relative;

  }




  .shadow {
    box-shadow: 0 .2rem 1rem rgba(0, 0, 0, .15) !important;
  }


  .rounded {
    border-radius: 6px !important;
  }

  /* table css*/
  .Fuel-table table {
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
  }

  .no_data_table {
    font-family: oswald;
    align-items: center;
    display: flex;
    min-height: calc(100vh - 175px);
  }

  .no_data_height {
    font-family: oswald;
    align-items: center;
    display: flex;
    min-height: calc(100vh - 175px);
  }

  .tableFooter {
    background: #aa000d;
    margin-top: 10px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .tableFooter small {
    font-size: 12px;
    margin-top: 4px;
    color: #fff;
  }


  .tableFooter select {
    padding-right: 24px !important;
    appearance: none;
    border-radius: 6px;
    padding: 3px 10px;
    margin-top: 5px;
  }

  .table>tbody>tr>td,
  .table>tbody>tr>th,
  .table>tfoot>tr>td,
  .table>tfoot>tr>th,
  .table>thead>tr>td,
  .table>thead>tr>th {
    vertical-align: middle;
  }

  .table td,
  .table th {
    color: #2E3B52;
    border: 0px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .table th {
    font-size: 14px;
  }

  .table td {
    font-size: 13px;
  }

  .table-striped>tbody>tr:nth-of-type(2n+1) {
    --bs-table-accent-bg: #F4F7FC;
  }

  .table>thead {
    vertical-align: bottom;
    background: #AA000D;
    color: #fff !important;
  }

  thead tr th {
    color: #fff !important;

  }

  .sticky-top {
    z-index: 1 !important;
  }

  .Fuel-table {
    height: calc(100vh - 236px);
    overflow: hidden;
    border-radius: 7px;

    .table-responsive {
      height: 100%;
    }
  }

  /* pagination css*/
  .pageInput {
    border: none;
    height: 32px;
    width: 42px;
  }

  .pageInput {
    color: #fff;
  }

  .total-pages {
    margin: 0 8px 2px -6px;
  }

  .page-link {
    color: #000;
    font-size: 17px;
  }

  .page-item:first-child .page-link {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .page-item .page-link {
    border-radius: 50%;
    width: 30px !important;
    height: 30px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin: 0 10px;
    cursor: pointer;
  }

  .groupSelect {
    background: #FC8F0B;
    border-color: #845ADF;
    color: #fff;
    border-radius: 6px;
    padding: 1px 10px;
    font-size: 14px;
    height: 35px;
    border: 0px;
  }

  /* dropdown menu icon */
  .context-menus .dropdown-toggle::after {
    display: none;
  }

  .context-menus .dropdown-item {
    font-size: 12px;
  }

  .material-symbols-outlined {
    cursor: pointer;
  }

  /* password view icon */
  .hide-password.material-symbols-outlined {
    right: 5px !important;
  }

  /*form label*/

  .user-form label {
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
    color: $label;
    font-size: 14px;
  }

  .error.form-control {
    border-color: $maroon;
  }

  #focusedInput,
  .form-control:focus {
    border: 1px solid $maroon !important;
    box-shadow: none;
    background: #eadbba;
  }

  /* color code */
  .blue {
    background-color: $blue;
  }

  .yellow {
    background-color: $yellow;
  }

  .purple {
    background-color: $purple;
  }

  .green {
    background-color: $green;
  }

  .badge-green {
    background-color: #688449;
  }

  .badge-yellow {
    background: #e27303;
  }

  .badge-brown {
    background: #b59547;
  }

  .badge-red {
    background: #f71f53;
  }

  .badge-blue {
    background: #4d9f9b;
  }

  .maroon {
    background-color: $maroon;
  }


  .circle-icon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    svg {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .circle-icon:hover {
    background: $maroon !important;
  }

  .main-content {
    position: relative;
  }



  @media (max-width: 576px) {
    .btn:not(.btn-sm) {
      padding: 8px 13px;
    }
  }

}

/* select list icon */
.custom-select {
  z-index: 9;
}

select.form-control,
select.form-control:focus {
  background: #fbf6f2 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHPSURBVHic7dgtSANhGMDx/4YiU5TJwGAQLAaLoBaLFoOYbDabzWjWbrTJgmsmm0EsrlgsFsOCRVAQBEV0m98abgc6N+69u/fj3D0/eMruBu//2MZ4QAghhBBCCCGESJ1MwPVBK6cw7z7Mzb1AEagCXx0y1UZTTuUBFBNwYFOz0xzb/BXIAo94n4JO9AT0/3wh23SD/6RSo9UDKDk4hy27KjflgDLuv6+6p4zijyDAAHCagEPrmjMgrxrvyzfe6PrwceccKISN9w0BlQRERJ0LYDhqvG8EuExATNi5AkbjxvvGgJsERKnOLTCuK943AdwlIC5oHoBp3fG+Gbx/U64j200NmDUV75sHnh2HtppXYNFg9y9LwJvhoDDzDiwbLW5hBfiIeXAd8wmsGm5ta03hgKZn3XhlgE3cxW9Y6FOyhf34bStlijJ42xZb8SWC95nWZYE9zMfvA12WmkLrBg4wF38E9FiriSgHHKM//gTos9gRi+6FSqSFhmsFvGVE3PgK3l7iX4q7UNGy0HAt6kLlGo0LDdfCLlSMLDRcU12oGF1ouBa0UKkBc85OZ8kCUOdvfL1xLRWm8P7VveBtlw6BSacnEkIIIYQQQgghRGp8A7sjgU5M3b7vAAAAAElFTkSuQmCC) no-repeat right .75rem center/auto 12px;
  height: 38px;
  padding-right: 30px;

}

/* datepicker  icon */

.rdrInputRange:last-child {
  display: none;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 1.7rem !important;
  line-height: 1.7rem !important;
  margin: 0.7rem !important;
  font-size: 14px !important;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: #fff;
}

.react-datepicker__header {
  background-color: #AA000D !important;
  border-bottom: #AA000D !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #fff;
}

.react-datepicker__day--selected {
  background-color: #AA000D;
}

.react-datepicker__day:hover {
  background-color: transparent;
  color: #AA000D;
}

.calenderIcons {
  right: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.datePicker button.btn-date-picker {
  background: #fbf6f0 !important;
  padding: 8px 10px;
}

/*detail view table*/
#moveContent {
  background: url(assets/Images/body_bg.jpg) no-repeat center bottom / cover;

  &>div {
    transition: all 0.4s ease;

    &.paneIsOpen {
      width: 68vw;
    }
  }

  .bg_slide.panel {
    background-color: #fff;
    height: 100vh;
  }


  .sliding-panel-container {
    position: fixed;
    background: #fff8ea;
    left: auto;
    right: -1000px;
    top: 0;
    width: 550px;
    transition: all 0.4s ease;
    z-index: 2111;

    &.active {
      right: 0;
    }

    .group_detailsWrapper {
      height: calc(100vh - 44px);
      overflow: auto;
      padding: 0 14px;

      .bg-light {
        background-color: #ffe7c8 !important;
      }
    }
  }
}

.panel {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 20px 20px 50px #ccc;
  overflow: hidden;
}

.paneIsOpen tr {
  cursor: pointer;
}

.filter-from {
  .datePicker button.btn-date-picker {
    background: transparent !important;
    width: 100% !important;
    text-align: left !important;

    svg {
      float: right;
    }
  }
}





/* delete pop up*/

.delete-popup {

  .fs-6 {
    font-size: 14px !important;
  }

  .alert span.material-symbols-outlined {
    color: #aa000d;
    float: left;
    padding: 0 2px;
    margin-top: -2px;
  }
}

/*table icon view / edit/ delete*/
.table-edit-icon .btn.btn-sm {
  &:hover {
    background-color: #aa000d !important;
    border-color: #aa000d;

  }
}

.table-edit-icon svg {
  fill: #fff;
}


.border-box {
  padding: 10px 10px 0 10px;
  margin-top: 30px;

  .heading-title {
    position: absolute;
    top: -22px;
    background: #fbf6f0;
  }

}

.download_icon {
  margin-left: 5px;
}

.client-filter {
  background: rgba(241, 144, 102, 0.44);
  font-size: 13px;
  border-radius: 6px;
  padding: 3px 5px;
  border: 1px solid #f19066;
}

.text-danger {
  font-size: 12px;
}

.user-form .form-group {
  position: relative;
  margin-bottom: 15px !important;
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
}

.user-form .date-picker-error .errorMessage {
  width: 100%;
}

.date-picker-error .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important;
}

.date-picker-error span.errorMessage+.MuiStack-root .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #AA000D !important;
}

.custom-toast {
  z-index: 99999 !important;
}

.base-Popper-root.MuiPickersPopper-root {
  z-index: 999999;
}

.blank_height {
  min-height: calc(100vh - 204px);
}

.height-page {
  min-height: calc(100vh - 175px);
}

// .modal-dialog .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper {
//   overflow: hidden;
// }
// .modal-dialog .MuiCalendarPicker-root {
//   overflow: hidden !important;
// }
// .modal-dialog .MuiMonthPicker-root {
//   height: 261px;
//   overflow: hidden;
// }
// .modal-dialog .MuiCalendarPicker-root {
//   height: 320px;
//   position: relative;
//   top: 0;
// }
// .modal-dialog .ui-datepicker-div {
//   position: absolute;
//   top: 100%;
//   z-index: 9999999999;
//   }

// .modal-dialog .muiYearPicker-root {
//   height: 200px;
// }

// .modal-dialog .MuiMonthPicker-root {
//   height: 100px;
// }
.MuiDialog-root.MuiModal-root {
  z-index: 9999 !important;
}

.MuiPopper-root.MuiPickersPopper-root {
  z-index: 9999;
}

/*responsive mobile view*/


@media (max-width: 1200px) {
  .update-transaction-modal .modal-dialog {
    max-width: 98%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .modal-dialog {
    max-width: 80%;
    margin: 0 auto;
  }

  #moveContent.reportWrapper .sliding-panel-container {
    width: 100%;
  }

  #moveContent.reportWrapper .paneIsOpen {
    width: 100%;
  }

}

@media (max-width: 991.98px) {
  footer {
    z-index: inherit !important;
  }
}

@media (max-width:600px) {
  .tableFooter .text-hidden-m {
    font-size: 0px;
  }

  body .page-item .page-link {
    width: 29px !important;
  }

}