.customerWrapper {
  .blank_height {
    min-height: calc(100vh - 201px);
  }


  .timeline-circle {
    height: 25px;
    width: 25px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;

    .material-symbols-outlined {
      font-size: 15px;
      cursor: default;
    }
  }

  .timeline-list .list-group-item>div>div {
    padding-left: 12px;
  }

  .customer-user {
    background: #fdf9f3;
    padding: 10px 0;
  }

  .profile-widget {
    background-color: #fdf9f3;
    border: 1px solid #e9e9ea;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 #0003;
    padding: 10px 10px 0 10px;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      height: 3px;
      width: 50%;
      border-bottom: 2px solid;
      position: absolute;
      top: -1px;
      margin: 0 auto;
      left: 24%;
      border-radius: 10px;
    }
  }

  .pdf-icon {
    margin-left: -8px;
  }

  .mb-4.col-lg-4.col-md-4:nth-child(1) .profile-widget:before {
    border-color: #2ca87f;
  }

  .mb-4.col-lg-4.col-md-4:nth-child(2) .profile-widget:before {
    border-color: #ff7f28;
  }

  .mb-4.col-lg-4.col-md-4:nth-child(3) .profile-widget:before {
    border-color: #845ADF;
  }

  .mb-4.col-lg-4.col-md-4:nth-child(4) .profile-widget:before {
    border-color: #2ca87f;
  }

  .mb-4.col-lg-4.col-md-4:nth-child(5) .profile-widget:before {
    border-color: #aa000d;
  }

  .bg-light {
    background-color: #ffe7c8 !important;
    padding: 10px 19px;

    .text-muted {
      color: #313233 !important;
    }

    .fw-semi-bold {
      line-height: 14px;
    }
  }

  // .address.ellipse-address {
  //   height: 54px;
  //   overflow-y: auto;
  //   }
}
.pdf-logo {
 .upload-size-image {
    height: 80px;
    width: 100px !important;
  }
  .upload-icon-image {
    background-color: rgba(170, 0, 13, 0.6);
  }
  .upload-icon-image span.material-symbols-outlined {
    color: #fff;
  }

}



.user-form .form-group.payment-date .errorMessage {
  top: 61px;
  width: 100%;
  left: 0;
}

.form-group.payment-description {
  margin-top: 5px;
}

.invoice .user-form .date-picker-error .errorMessage {
  top: 62px;
}

.icon-svg-dots.payment-icon svg {
  margin-left: -6px;
}

.icon-svg-dots {
  width: 16px;
}

.editButton {
  a {
    color: #fff;
    font-weight: 500;
  }

  .dropdown-menu {
    min-width: 8rem;
    background-color: #AA000D;
    z-index: 9999;

    svg {
      fill: "#fff";
    }
  }
}

.enable-caret .dropdown-toggle::after {
  display: none;
}

.pdf-modal .modal-dialog {
  max-width: 800px !important;
}

.user-form .form-group.form-group-m-zero {
  margin: 0px !important;
}


.Fuel-table.invoice-table {
  height: 250px;
}

.Fuel-table.invoice-table .table>thead {
  background: #625F5F;
  top: -1px;
}

.invoice .no_data_table {
  height: 250px;
  min-height: auto;

  .NOData {
    width: 100px;
  }
}

.amount-label {
  border-top: 1px solid #eee;
}

.modal-filter-icon .circle-icon {
  height: 30px;
  width: 30px;
}

.modal-filter-icon .circle-icon:hover {
  background: #49B6F5 !important;
}

.user-form .vat-error .errorMessage {
  bottom: -24px;
}
.amount-label.d-flex.justify-content-end {
  padding: 10px;
  background-color: #fbf6f0;
  font-weight: bold;
  font-size: 1.2rem;
  padding-right: 20px;
}
@media (max-width: 800px) {
  .pdf-modal .modal-dialog {
    max-width: 98% !important;
  }

  .user-form .vat-error .errorMessage {
    bottom: -14px;
  }
}
@media (max-width: 600px) {
.logo_pd_file {
	width: 100%;
	margin: 0 auto;
	text-align: center;
}
}
