.alarmWrapper {
    background: url(../../assets/Images/body_bg.jpg) no-repeat center bottom / cover;           
    .btn.btn-outline-primary {
        background: #e0f3ff;
        border-color: #e0f3ff;
        width: 100px;
      }

      .btn.btn-outline-danger {
        background: #ffeae7;
        border-color: #ffeae7;
        width: 100px;
      }
      .btn-outline-success {
        color: #198754;
        border-color: #e2ffe1;
        background: #e2ffe1;
        width: 100px;
      }
    
    .table-bordered>:not(caption)>* {
        border-width: 0;
    }
    .Fuel-table {
      height: calc(100vh - 227px);
    }

    .table-striped>tbody>tr:nth-of-type(2n+1) {
        --bs-table-accent-bg: #f9f6ef;
        border: 0px;
    }

    .table {
        thead {
            vertical-align: bottom;
            background: #af9763;
            color: #000 !important;
        }

        th {
            color: #2E3B52;
            font-size: 14px;
            font-weight: normal;
            border: 0px;
            padding: 0.425rem 1.25rem;
            white-space: nowrap;
            background: #af9763;
        }

        td {
            color: #2E3B52;
            font-size: 13px;
            font-weight: normal;
            border: 0px;
            padding: 0.425rem 1.25rem;
            white-space: nowrap;
        }

    }

    .border {
        border: 1px solid #af9763 !important;
    }

    .sorting-head .material-symbols-outlined {
        top: 11px !important;
    }

    .tableFooter {

        .page-link {
            background: #af9763 !important;
            color: #fff !important;
        }
        .page-item:first-child .page-link {
            background: #f1daa3 !important;
            color: #000 !important;
          }
       .page-item:last-child .page-link {
        background: #f1daa3 !important;
        color: #000 !important;
          }
    }
    .alarm-color.badge {
      color: #fd8684;
      font-size: 12px;
      padding: 5px 15px;
    }
    .tableFooter.alarmFooter {
      background: #af9763;
      margin-top: 0px;
    }
}

.tableFooter select {
    padding-right: 24px !important;
    appearance: none;
    border-radius: 6px;
    padding: 3px 10px;
      padding-right: 10px;
    margin-top: 5px;
}
.alarm-datepicker button.btn-date-picker {
  width: 100%;
  text-align: left !important;
  svg {
    float: right;
  }
}

.alarm-datepicker .datePicker.px-2 {
  padding: 0px !important;
}