
  .report-preview-modal .modal-dialog {
    max-width: 96%;
  }
  .report-preview-modal .table-responsive {
    height: 400px;
  }
  .download-report .circle-icon {
    height: 25px;
    width: 25px;
  }
  .report-preview-modal  .table > thead {
    background: #625F5F;
  }
  .report-preview-modal .no_data_height {
    height: calc(100vh - 251px);
}
.total-report-review {
  margin-top: -32px;
}
@media (max-width: 1200px) { 
  .total-report-review {
    margin-top: 0;
  }
  }
  @media (max-width: 850px) { 
    .total-report-review .list-group.list-group-horizontal {
    display: block;
  }
  .total-report-review {
    margin-top: -32px;
  }
}
@media (max-width: 767px) { 
  .total-report-review {
    margin-top: 0;
  }
  }
  // .report-preview-modal.modal {
  //   z-index: 10600;
  // }