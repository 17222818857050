#moveContent {
  background: url(../../assets/Images/body_bg.jpg) no-repeat center bottom / cover;

  &.driverWrapper {
    .sliding-panel-container {
      width: 550px;
      background: #fff8ea;
      top: 0;

      .group_detailsWrapper {
        overflow: auto;
        padding: 25px;
        height: calc(100vh - 51px);
      }
    }

    .paneIsOpen {
      width: calc(100% - 550px);

      .col-12.col-lg-6.col-md-6.col-xxl-4.col-xl-4 {
        width: 100% !important;
      }
    }

    .card_height {
      height: calc(100vh - 252px);
      overflow-y: auto;
      overflow-x: hidden;

    }

    .Fuel-table {
      height: calc(100vh - 252px);
    }

  }

  // .blank_height {
  //   min-height: calc(100vh - 250px);
  // }

}

.drivingLicence {
  text-transform: uppercase;
  padding: 4px 6px;
  border: 2px solid #313131;
  border-radius: 5px;
  display: table;
  line-height: 1;
  text-align: center;

  small {
    font-size: 0.75rem;
    letter-spacing: 2px;
  }

  p {
    margin: 0;
    // font-size: 2.2rem;
    font-size: 22px;
    font-family: 'Oswald';
    letter-spacing: 1px;

  }
}

.detailviewCircleParent {
  div:first-child .detailviewCircle {
    border-color: #d93a3c;
  }

  div:nth-child(2) .detailviewCircle {
    border-color: #ff7f28;
  }

  div:last-child .detailviewCircle {
    border-color: #585f65;
  }

  .detailviewCircle {
    padding: 10px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    border-left: 5px solid;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #ffe7c8;

    img {
      display: block;
      width: 50px;
      height: auto;
      margin: 0 10px 0 0;
      opacity: 0.3;
    }
  }

  .error-text {
    border-top: 1px solid #aa000d78;
    padding-top: 5px;
  }

}

.active-card {
  background: #F0A26A !important;

  .active-mute-text .text-muted {
    color: #fff !important;
  }

  .bg-light.active-bg-light {
    background-color: #f9b382 !important;
  }
}


// .card.card-body.rounded.shadow.drivercard.bg-white.h-100.active-card {
//   box-shadow: 0 .2rem 1rem #aa000d !important;
//   border: 2px solid #aa000d;
// }
// .card.card-body.rounded.shadow.drivercard.bg-white.h-100.active-card:nth-child(1) {
//   order: 1 !important;

// }

@media (min-width: 1200px) and (max-width: 1300px) {
  .drivingLicence p {
    font-size: 19px;
  }

  .drivercard .badge {
    font-size: 10px;
  }
}

@media (max-width: 991.98px) {
  #moveContent.driverWrapper .paneIsOpen {
    width: 100%;
  }

  #moveContent.driverWrapper .sliding-panel-container {
    width: 100%;
  }

  .drivingLicence {
    margin-top: 10px;
  }
  #moveContent.driverWrapper .card_height {
    height: auto;
  }
}

.edit-pop-detial {
  position: absolute;
  right: 3px;
  top: 10px;
}