$maroon: #AA000D;
$white: #fff;

.leaflet-interactive {
  stroke: $maroon;
}

.live-tracking {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
}

.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
  top: 11px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: $maroon;
  color: $white;

}

.leaflet-container a.leaflet-popup-close-button {
  color: $white;

}

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
  color: $white;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  height: calc(100vh - 181px);
}
.map-full-screen .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  height: 100vh;
}
.map-full-screen {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  top: -5px;
}
.btn.btn-btn-secondary.arrow-swap {
  padding: 5px 10px;
}